.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}



.no-id{
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(-225deg,#165f8f,#0a254c);
  color: rgba(255, 255, 255, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-id button {
  background: white;
  padding: 15px 25px;
  font-size: 20px;
  text-transform: uppercase;
  border: solid 1px black;
  font-weight: bold;
  cursor: pointer;
}

.no-id img {
  margin-top: 30px;
  width: 300px;
}