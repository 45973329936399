/* Utilities */

.clear{
    clear:both;
}

/* /Utilities */

html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    background-color: #FFFFFF;
}

#root {
    height: 100%;
}

.spacing {
    margin-top: 30px;
}

.spacing-bottom {
    margin-bottom: 30px;
}

.red {
    color: #b70000;
}

.fb-btn {
    background: #3b5998;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.fb-p {
    margin-bottom: 40px;
}

.text-center {
    text-align: center;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
}

.sidebarRight {
    height: auto;
    min-height: 100vh;
    margin-left: 0;
    -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
    box-shadow: 1px 0 0 #e1e1e1 inset;
    background-color: #eceff1;
    float: right;
    order: 2;
    width: 35%;
}

.sidebarRight__container {
    padding: 5em 25px;
}

.mainDesktop {
    width: 65%;
    height: auto;
    float: left;
    order: 1;
}

.text--main {
    font-size: 14px;
}

.mainDesktop__container {
    width: 100%;
    max-width: 550px;
    margin: auto;
    margin-top: 5em;
    border: 1px solid #eceff1;
    padding: 30px 60px;
    border-radius: 5px;
    box-shadow: 0 18px 35px rgba(50,50,93,.1), 0 8px 15px rgba(0,0,0,.07);
    /*margin-right: 10em;*/
}

.mainDesktop__container h2 {
    text-align: center;
}

.mainMobile {
    order: 1;
    flex: 0 0 100%;
}

.product {
    clear: both;
}

.product__title{
    color: #000;
    padding-top: 7px;
    font-size: 14px;
    margin-left: 10px;
}

.product__img {
    float: left;
}

.product__img .img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #9d9fa0;
    /*max-width: 80px;*/
    /*max-height: 80px;*/
    background-size: cover;
}

.powered-logo-mobile {
    display: none;
}


.product__desc {
    float: left;
    width: 60%;
}

.product__desc h3 {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.product__desc p {
    margin: 0px;
}

.product__price {
    float: right;
    width: 20%;
    font-size: 14px;
    font-weight: 600;
}

.product__qty {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.product__qty__label {
    display: inline-block;
    margin-top: 16px;
    margin-right: 8px;
    margin-left: 10px;
    float: left;
    font-size: 10px;
}

.product__qty__select{
    font-size: 10px!important;
}

.list__product{
    min-height: 70px;
    padding: 16px 36px 0px 0px !important;
}

.list__product__sub{
    padding:0 !important;
    font-size: 12px !important;
    min-height: 50px;
    margin-left: 21px !important;
}

.list__product__sub .product__img .img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9d9fa0;
    /*max-width: 80px;*/
    /*max-height: 80px;*/
    background-size: cover;
}

.list__product__sub .product__title{
    color: #000;
    padding-top: 7px;
    font-size: 12px !important;
    margin-left: 10px;
}

.coupon {
    width: 100% !important;
    margin-bottom: 15px;
}

.totals {
    width: 100%;
    float: left;
    padding-bottom: 1em;
}

hr {
    width: 100%;
    display: flex;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.totals__subtotal {
    width: 100%;
    color: #737c82;
    padding-top: 7px;
    font-size: 14px;
}

.totals__tax {
    width: 100%;
    color: #737c82;
    padding-top: 7px;
    font-size: 14px;
}

.totals__price {
    width: 20%;
    float: right;
    text-align: right;
    font-weight: 600;
    color: #313131;
}

.info-tooltip {
    height: 18px !important;
    width: 18px !important;
    vertical-align: middle !important;
    padding: 0 !important;
}

.removebtn {
    background: transparent !important;
    padding: 0px !important;
    margin: 0px !important;
    min-width: auto !important;
    float: right;
    margin-top: 7px!important;
    margin-left: 30px!important;
}

.removebtn span {
    background: transparent !important;
    padding: 0px !important;
    margin: 0px !important;
    min-width: auto !important;
    margin-top: -10px !important;
    color: #b70000 !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
}

.stepperr {
    place-content: center !important;
}

.step_label {
    padding-left:0!important;
}

.step_content {
    margin-left:12px!important;
}

.nextBtn {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    display: block !important;
}

.loginBtn {
    box-shadow: none !important;
    min-width: 65px !important;
}

.loginBtn button {
    background: transparent !important;
}

.loginBtn button span {
    text-transform: capitalize !important;
    color: #035dd4 !important;
    padding-left: 0px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.loginBtn-2 {
    display: block !important;
    margin-top: 15px !important;
}

.login-row {
    text-align: center;
}

.login-row span {
    color: grey;
}

.buyBtnContainer{
    clear:both;
}

.buyBtn, #paypal-button {
    display: block !important;
    width: 100%;
    margin: 20px auto;
}

.backBtn {
    margin-top: 15px !important;
    background-color: transparent !important;
}

.backBtn span {
    font-size: 14px !important;
}

.backBtn-2 {
    box-shadow: none !important;
}

.backBtn-2 button {
    background: transparent !important;
    box-shadow: none !important;
}

.backBtn-2 span {
    font-size: 14px !important;
    color: black !important;
}

.payBack {
    text-align: center;
}

.formLines {
    width: 50% !important;
    float:left;
    font-size: 15px !important;
}

.browser-default {
    font-size: 15px;
}

.billingCountry {
    width: 100% !important;
}

.centerText {
    text-align: center;
}

.centerText span {
    margin-top: 15px;
}

.logoutBtn {
    box-shadow: none !important;
    min-width: 65px !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    background-color: transparent !important;
}

.logoutBtn button {
    background: transparent !important;
}

.logoutBtn span {
    text-transform: capitalize !important;
    color: #035dd4 !important;
    padding-left: 5px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.cardsImg {
    width: 40%;
}

.topBanner {
    width: 100% !important;
    background: black;
    color: white;
    background: -webkit-linear-gradient(70deg, rgb(13, 27, 40), rgb(0, 55, 74));
    background: linear-gradient(90deg, rgb(13, 27, 40), rgb(0, 55, 74));
    max-height: 73px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.topBanner .vendor-logo{
    height: 70px;
    max-width: 100px;
    object-fit: contain;
}

.topBanner h1 {
    float: left;
    font-size: 27px;
    font-weight: 300;
}

.topBanner .powered-by-genflow-logo {
    float: right;
    height: 50px;
    object-fit: contain;
}

.subtitle {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #3c3c3c;
}

.seller-name{
    font-weight: normal;
}

.paymentTitle {
    width: 100% !important;
    display: inline-table;
    margin-top: 15px;
}

.paymentTitle .subtitle {
    float: left;
}

.paymentTitle .cardsImg {
    float: right;
    margin-top: 8px;
}

.bottomLinks {
    bottom: 0;
    position: fixed;
    background: #e6ecef;
    width: 100%;
    margin-left: -25px;
    padding: 8px 0px 10px 20px;
    z-index: 999;
}

.bottomLinks a {
    font-size: 12px;
    text-decoration: none;
    color: #2fa5ff;
    padding-right: 20px;
}

.download-btn img {
    height: 45px;
    padding: 0px 5px;
}

.check-box {
    width: 80px;
}

.logo-top {
    margin-top: 20px;
    max-width: 300px;
    margin-bottom: 35px;
}

.sxyfit-logo {
    max-width: 200px;
}

.thrst-logo {
    max-width: 120px;
    margin-bottom: 20px;
}


.honey-photo {
    width: 60%;
}

.brand-logo {
    max-width: 150px;
    display: block;
    margin: 10px auto 20px auto;
}

.olie-logo {
    max-width: 150px;
    margin-bottom: 5px;
    margin-top: 15px;
}

.check-box-small {
    width: 20px;
    margin-left: 10px;
}

.grace-logo {
    width: 200px;
    margin-bottom: 20px;
}

.continue-class {
    margin-bottom: 25px;
    padding-top: 15px;
}

.aflete-badge img {
    width: 100px;
    margin-top: 15px;
}

.genflow-badge {
    margin-top: 50px;
    background: black;
}

.genflow-logo {
    margin-top: 50px;
}

.genflow-logo img {
    width: 100px;
}

.genflow-badge img {
    width: 100px;
    margin: 15px;
}

.problem-line {
    background-color: #eceff1;
    border-radius: 10px;
    padding: 15px 15px;
}

.terms {
    text-align: left;
    padding-top: 5em;
}

.terms .mainDesktop__container {
    max-width: 80%;
}

.thank-you {
    text-align: center;
    padding-top: 5em;
}

.thank-you-grace {
    color: #121212;

}

.thank-you-grace hr {
    padding-bottom: 20px;
}

.thank-you .mainDesktop__container {
    margin-top: 0 !important;
}

.back-to {
    padding-bottom: 35px;
}

.error-title {
    color: #bd0000;

}

.error-title h1 {
    font-size: 80px;
    margin: 0;
}

.block-ui-container{
    position:fixed;
}

.block-ui-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.85;
    filter: alpha(opacity=85);
    background-color: white;
}

#card_number {
    box-shadow: none !important;
}

.cardElement{
    display:inline-block;
    border-bottom: 1px solid rgba(0,0,0, 0.12);
    padding:4px 0;
    margin:4px 0;
    width:100%;
}

.addCardBtn{
    color: white!important;
    line-height: 14px!important;
    float: right;
    margin-top: 10px!important;
}

.addCardBtn span{
    padding-left: 10px!important;
    padding-right: 10px!important;
}

.paypal-button-disabled{
    display:none;
    border-radius: 4px;
    height: 45px;
    min-height: 30px;
    max-height: 55px;
    background: #e0e0e0;
    color: #111;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: none;
    vertical-align: top;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: none;
    font-weight: 500;
    text-align: center;
}

.paypal-button-disabled-text{
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    text-align: left;
    white-space: pre;
}

.paypal-button-disabled-logo{
    vertical-align: top;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    text-align: left;
    height: 21px;
    max-height: 27px;
    min-height: 18px;
}

div.paypal-button-disabled:only-child {
    display:block;
}

.billingCountry {
    width: 100%;
}

.thin-h3 {
    font-weight: 400;
}

/* Custom material UI select */

/* ==========  Select Field Variables ========== */
/* ==========  Select Field ========== */
/* Style Select Field */
select {
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
    color: rgba(0,0,0, 0.26);
    border: none;
    border-bottom: 1px solid rgba(0,0,0, 0.12);
}

/* Remove focus */
select:focus {
    outline: none;
}

/* Hide label */
.mdl-selectfield label {
    display: none;
}

/* Use custom arrow */
.mdl-selectfield select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.mdl-selectfield .selected {
    color: black;
}

.mdl-selectfield {
    position: relative;
    display: inline-block;
}
.mdl-selectfield:after {
    position: absolute;
    top: 0.75em;
    right: 0.5em;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    border-top: 0.375em solid rgba(0,0,0, 0.12);
    pointer-events: none;
}

.mdl-selectfield {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.mdl-selectfield select {
    border-radius: 0px !important;
}

.tracking_code {
    display: inline-block;
    width: 46%;
}

.tracking_code select{
    padding: 7px 0;
}

.tracking_code:after{
    top: 1em;
}

.tracking_number {
    display: inline-block !important;
    width: 100% !important;
}

.tracking_number_small_text{
    display: block;
}

.termsLine {
    margin-bottom: 30px;
}

.termsCheckbox {
    float:left;
    width: 30px!important;
}

.termsText {
    margin-top: 5px;
    display: inline-block;
}

.inline_emoji_left {
    padding: 0 10px 0 0;
}

.paymentHeader {
    position: relative;
    margin-top: 40px;
}

.paymentHeader hr{

}

.paymentHeader p{
    position:absolute;
    margin-top: -24px;
    font-size: 12px;
    display: inline-table;
    padding: 0 5px;
    text-align: center;
    background: #f8fbfd;
}

.PaymentRequestButton{
    margin-top: 30px;
}

@media only screen and (max-width: 1600px) {
    .product__img .img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #9d9fa0;
        /*max-width: 80px;*/
        /*max-height: 80px;*/
        background-size: cover;
    }

    .terms .mainDesktop__container {
        max-width: 80%;
    }
}

@media only screen and (max-width: 1400px) {
    .product__img .img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #9d9fa0;
        /*max-width: 80px;*/
        /*max-height: 80px;*/
        background-size: cover;
    }

    .terms .mainDesktop__container {
        max-width: 80%;
    }
}

@media only screen and (max-width: 1200px) {
    .product__desc {
        width: 50%;
    }
    .product__img .img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #9d9fa0;
        /*max-width: 80px;*/
        /*max-height: 80px;*/
        background-size: cover;
    }

    .mainDesktop__container {
        max-width: 450px;
    }

    .terms .mainDesktop__container {
        max-width: 80%;
    }
}

@media only screen and (max-width: 991px) {
    /*.coupon div {*/
    /*width: 100% !important;*/
    /*}*/
    .product__img {
        float: left;
    }
    .product__desc h3 {
        margin-bottom: 0px;
    }
    .totals__total {
        padding-bottom: 25px;
    }
    .formLines {
        width: 100% !important;
    }
    .cardsImg {
        width: 40%;
        max-width: 200px;
    }
    /*.centerText {*/
    /*padding-top: 50px;*/
    /*}*/
}

@media only screen and (max-width: 768px) {
    .mobile-container {
        width: 90%;
        margin: auto;
    }

    .product__img .img {
        width: 90px;
        height: 90px;
    }

    .bottomLinks {
        text-align: center;
        position: inherit;
    }
    .secure-title {
        text-align: center;
    }
    #coupon_submit {
        background: grey !important;
    }
    .mobile-container h3 {
        margin-bottom: 5px;
        font-size: 16px;
    }

}

@media only screen and (max-width: 480px) {
    .product__img .img {
        width: 50px;
        height: 50px;
    }

    .login-row {
        text-align: left !important;
    }

    .totals__total {
        font-size: 15px !important;
    }

    /*.product__desc h3 {*/
    /*font-weight: 600 !important;*/
    /*}*/

    .secure-title span {
        color: grey !important;
    }

    .mobile-container {
        width: 90%;
        margin: auto;
    }
    .topBanner {
        font-size: 18px;
        padding: 0 1em !important;
    }
    .topBanner h1 {
        font-size: 18px;
    }
    .topBanner .powered-by-genflow-logo {
        width: 60px;
        object-fit: contain;
    }
    .mobile-container h3 {
        margin-bottom: 5px;
        font-size: 15px;
    }
    .mobile-heading {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0;
        color: #3c3c3c;
    }
    .mobile-subtitle {
        margin-top: 0px;
        margin-bottom: 15px;
        color: grey;
        font-size: 14px;
    }
    .totals__total {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .totals hr {
        display: none;
    }
    .coupon {
        display: inline-flex;
    }
    .coupon div {
        box-shadow: none !important;
    }
    .coupon #coupon_submit {
        width: 90% !important;
        float: right;
    }
    .coupon #coupon {
        width: 170px;
    }
    .product__desc {
        width: 60%;
    }
    .subtitle {
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 0px;
    }
    .cardsImg {
        width: 40%;
        text-align: center;
        margin: auto;
    }
    .paymentTitle .cardsImg {
        float: none;
    }
    .mainDesktop__container {
        max-width: 320px !important;
        margin: auto;
        padding: 30px !important;
        box-shadow: none !important;
        border: none;
    }
    .totals {
        padding-bottom: 1em !important;
    }
    .bottomLinks {
        position: inherit;
        background: transparent;
        margin-bottom: 25px !important;
        width: 100%;
        margin: auto;
    }
    .secure-title {
        color: grey;
        text-align: center;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .paymentTitle .subtitle {
        padding-right: 10px;
    }

    .mobile-container div {
        font-size: 14px;
    }

    #coupon_submit {
        background: grey !important;
    }

    .powered-logo-mobile {
        display: block;
        width: 100px;
        text-align: center;
        margin: auto;
        padding-top: 10px !important;
    }
    .powered-logo-p {
        text-align: center;
    }
}
